<template>
  <div class="list-container">
    <el-table
        ref="tableList"
        :cell-style="{height:36+'px'}"
        :data="dataList"
        border
        style="width: 100%">
      <!-- 基础数据 -->
      <template v-if="pageName === 'baseData'">
        <el-table-column prop="no" label="序号" width="48"></el-table-column>
        <el-table-column prop="costEnterpriseName" label="老成本主体名称" width="274"></el-table-column>
        <el-table-column prop="costEnterpriseId" label="老成本主体ID" width="96"></el-table-column>
        <el-table-column prop="costEnterpriseNCCode" label="老成本NC编码" width="180"></el-table-column>
        <el-table-column prop="investEnterpriseName" label="投管主体名称" width="274"></el-table-column>
        <el-table-column prop="investEnterpriseId" label="投管主体ID" width="96"></el-table-column>
        <el-table-column prop="investEnterpriseNCCode" label="投管NC编码"></el-table-column>
      </template>
      <!-- 主合同数据 -->
      <template v-if="pageName === 'mainContract' || pageName === 'unionOrder'">
        <el-table-column prop="contractName" label="合同名称" width="138"></el-table-column>
        <el-table-column prop="contractNo" label="合同编号" width="112"></el-table-column>
        <el-table-column prop="contractTypeName" label="合同类型" width="74"></el-table-column>
        <el-table-column prop="ourCompany" label="我方签约主体" width="138"></el-table-column>
        <el-table-column prop="supplierCompany" label="对方签约主体" width="138"></el-table-column>
        <el-table-column prop="currency" label="币种" width="54" :formatter="stateFormat"></el-table-column>
        <el-table-column prop="contractAmount" label="合同金额" width="102" :formatter="stateFormat"></el-table-column>
        <el-table-column prop="projectName" label="所属项目" width="132" :formatter="stateFormat"></el-table-column>
        <el-table-column prop="operatorName" label="经办人" width="90" :formatter="stateFormat"></el-table-column>
        <el-table-column prop="contractDateStr" label="经办日期" min-width="102"></el-table-column>
        <el-table-column label="操作" v-if="$route.name === 'mainContract'">
          <template slot-scope="scope" >
            <span class="handle-button" v-if="listType === '1'" @click="operate('cancel', scope.row)">取消同步</span>
            <span class="handle-button" v-if="listType === '0'" @click="operate('delete', scope.row)">删除</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" v-if="$route.name === 'unionOrder'">
          <template slot-scope="scope">
            <span class="handle-button" v-if="listType === '0' && scope.row.relevantStatus == '1'" @click="operate('relevance', scope.row)">关联合同</span>
            <span class="handle-button" v-if="listType === '0' && scope.row.relevantStatus == '2'" @click="operate('cancelRelevance', scope.row)">取消关联</span>
            <span class="handle-button" v-if="listType === '0'" @click="operate('delete', scope.row)">删除</span>
            <span class="handle-button" v-if="listType === '1'" @click="operate('cancel', scope.row)">取消同步</span>
          </template>
        </el-table-column>
      </template>
      <!-- 补充协议数据 -->
      <template v-if="pageName === 'agreeMentMove'">
        <el-table-column prop="agreementName" label="补充协议名称" width="137"></el-table-column>
        <el-table-column prop="agreementNo" label="补充协议编号" width="132"></el-table-column>
        <el-table-column prop="agreementTypeName" label="补充协议类型" width="96"></el-table-column>
        <el-table-column prop="operatorName" label="经办人" width="90"></el-table-column>
        <el-table-column prop="modifyDateStr" label="经办日期" width="102"></el-table-column>
        <el-table-column prop="contractName" label="主合同名称" width="113"></el-table-column>
        <el-table-column prop="contractNo" label="主合同编号" width="132"></el-table-column>
        <el-table-column prop="ourCompany" label="我方签约主体" width="137"></el-table-column>
        <el-table-column prop="supplierCompany" label="对方签约主体" width="137"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="handle-button" v-if="listType === '0'" @click="operate('delete', scope.row)">删除</span>
            <span class="handle-button" v-else @click="operate('cancel', scope.row)">取消同步</span>
          </template>
        </el-table-column>
      </template>
      <!-- 合同变更数据 -->
      <template v-if="pageName === 'modifyMove'">
        <el-table-column prop="modifyName" label="合同变更单名称"></el-table-column>
        <el-table-column prop="modifyNo" label="合同变更单编号" width="132"></el-table-column>
        <el-table-column prop="modifyTypeName" label="合同变更类型" width="132"></el-table-column>
        <el-table-column prop="operatorName" label="经办人" width="90"></el-table-column>
        <el-table-column prop="modifyDateStr" label="经办日期" width="132"></el-table-column>
        <el-table-column prop="contractName" label="主合同名称" width="153"></el-table-column>
        <el-table-column prop="contractNo" label="主合同编号" width="132"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="handle-button" v-if="listType === '1'" @click="operate('cancel', scope.row)">取消同步</span>
            <span class="handle-button" v-else @click="operate('delete', scope.row)">删除</span>
          </template>
        </el-table-column>
      </template>
      <!-- 合同附件数据 -->
      <template v-if="pageName === 'attachFile'">
        <el-table-column prop="contractName" label="合同名称" width="198"></el-table-column>
        <el-table-column prop="contractNo" label="合同编号" width="123"></el-table-column>
        <el-table-column prop="dataType" label="附件类型" width="96"></el-table-column>
        <el-table-column prop="fileName" label="附件名称" width="198"></el-table-column>
        <el-table-column prop="fileServicePath" label="附件地址"></el-table-column>
        <el-table-column prop="createDate" label="创建日期" width="137"></el-table-column>
        <el-table-column label="操作" width="89">
          <template slot-scope="scope">
            <span class="handle-button" v-if="listType === '1'" @click="operate('cancel', scope.row)">取消同步</span>
            <span class="handle-button" v-else @click="operate('delete', scope.row)">删除</span>
          </template>
        </el-table-column>
      </template>
      <!-- 合同付款数据 -->
      <template v-if="pageName === 'contractPay'">
        <el-table-column prop="contractName" label="合同名称"></el-table-column>
        <el-table-column prop="contractNo" label="合同编号" width="127"></el-table-column>
        <el-table-column prop="ourCompany" label="我方签约主体" width="120"></el-table-column>
        <el-table-column prop="supplierCompany" label="对方签约主体" width="180"></el-table-column>
        <el-table-column prop="currency" label="币种" width="54"></el-table-column>
        <el-table-column prop="payAmount" label="计划付款金额" width="99"></el-table-column>
        <el-table-column prop="actualPayAmount" label="实际付款金额" width="99"></el-table-column>
        <!-- <el-table-column prop="date" label="付款编号" width="127"></el-table-column> -->
        <el-table-column prop="operatorName" label="经办人" width="89"></el-table-column>
        <el-table-column prop="planPayDate" label="计划付款日期" width="137"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="handle-button first-button" v-if="listType === '1'" @click="operate('details', scope.row)">{{ scope.row.costUrl ? '详情' : '-' }}</span>
            <span class="handle-button" v-if="listType === '1'" @click="operate('cancel', scope.row)">取消同步</span>
            <span class="handle-button" v-else @click="operate('delete', scope.row)">删除</span>
          </template>
        </el-table-column>
      </template>
      <!-- 审批记录数据 -->
      <template v-if="pageName === 'approveRecord'">
        <el-table-column prop="sourceContractName" label="合同名称"></el-table-column>
        <el-table-column prop="sourceContractNo" label="合同编号" width="228"></el-table-column>
        <el-table-column prop="approvalTime" label="发起审批日期" width="228"></el-table-column>
        <el-table-column label="操作" width="108">
          <template slot-scope="scope">
            <span class="handle-button first-button" v-if="listType === '1'" @click="operate('details', scope.row)">详情</span>
            <span class="handle-button" v-if="listType === '1'" @click="operate('cancel', scope.row)">取消同步</span>
            <span class="handle-button" v-else @click="operate('delete', scope.row)">删除</span>
          </template>
        </el-table-column>
      </template>
      <!-- 暂无数据 -->
      <template slot="empty">
        <img class="error-hint-pic" src="../../assets/img/empty.png" alt="" />
        <p class="error-hint-text" v-if="$route.name === 'baseData'">暂无基础数据</p>
        <p class="error-hint-text" v-else-if="$route.name === 'mainContract'">暂无主合同信息数据</p>
        <p class="error-hint-text" v-else-if="$route.name === 'unionOrder'">暂无工联单信息数据</p>
        <p class="error-hint-text" v-else-if="$route.name === 'agreeMentMove'">暂无补充协议信息数据</p>
        <p class="error-hint-text" v-else-if="$route.name === 'modifyMove'">暂无合同变更信息数据</p>
        <p class="error-hint-text" v-else-if="$route.name === 'attachFile'">暂无合同附件数据</p>
        <p class="error-hint-text" v-else-if="$route.name === 'contractPay'">暂无合同付款数据</p>
        <p class="error-hint-text" v-else-if="$route.name === 'approveRecord'">暂无审批记录数据</p>
      </template>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      class="pagination-box"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :pager-count="5">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageSizes: [10, 20, 40]
    }
  },
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pageNum: {
      type: Number,
      default: 1
    },
    listType: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit('currentPageChange', val)
    },
    handleSizeChange(val) {
      this.$emit('pageSizeChange', val)
    },
    operate(type, val) {
      this.$emit('operate', type, val)
    },
    stateFormat(row, column, cellValue, index) {
      if (cellValue === '' || cellValue === null) {
        return '-'
      } else {
        return cellValue
      }
    }
  },
  computed: {
    pageName() {
      return this.$route.name
    }
  },
  mounted() {
    if (this.$route.name === 'approveRecord' || this.$route.name === 'attachFile') {
      this.pageSizes.push(1000)
    }
  }
}
</script>

<style lang="less" scoped>
.list-container{
  width: 100%;
  padding: 0 18px;
  box-sizing: border-box;
}
.pagination-box{
  text-align: right;
  margin: 10px 0;
}
.error-hint-pic{
  margin-top: 24px;
  margin-bottom: 13px;
}
.error-hint-text{
  margin: 0;
}
::v-deep .el-table__empty-text{
  line-height: normal;
  padding-bottom: 42px;
}
::v-deep .el-table .cell{
  // text-overflow: unset;
}
.handle-button{
  font-size: 16px !important;
  color: #997236;
  cursor: pointer;
  &:nth-child(n+2) {
    margin-left: 12px;
  }
}
.first-button{
  padding-right: 12px;
}
</style>